// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-writtenpiece-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/templates/writtenpiece.js" /* webpackChunkName: "component---src-templates-writtenpiece-js" */),
  "component---src-templates-artpiece-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/templates/artpiece.js" /* webpackChunkName: "component---src-templates-artpiece-js" */),
  "component---src-templates-interviewpiece-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/templates/interviewpiece.js" /* webpackChunkName: "component---src-templates-interviewpiece-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-art-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-donate-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-editorsnote-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/editorsnote.js" /* webpackChunkName: "component---src-pages-editorsnote-js" */),
  "component---src-pages-fiction-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/fiction.js" /* webpackChunkName: "component---src-pages-fiction-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interview-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/interview.js" /* webpackChunkName: "component---src-pages-interview-js" */),
  "component---src-pages-personalessay-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/personalessay.js" /* webpackChunkName: "component---src-pages-personalessay-js" */),
  "component---src-pages-poetry-js": () => import("/codebuild/output/src3332503682/src/womensissue/src/pages/poetry.js" /* webpackChunkName: "component---src-pages-poetry-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src3332503682/src/womensissue/.cache/data.json")

