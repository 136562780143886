module.exports = [{
      plugin: require('/codebuild/output/src3332503682/src/womensissue/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700},
    },{
      plugin: require('/codebuild/output/src3332503682/src/womensissue/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135918676-1"},
    },{
      plugin: require('/codebuild/output/src3332503682/src/womensissue/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src3332503682/src/womensissue/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
